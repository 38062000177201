/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, CookieManager, Swiper) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $body = $('body'),
            $cookieBanner = $('.cookie-banner'),
            $cookieClose = $cookieBanner.find('.item-close'),
            $cookieAccept = $cookieBanner.find('.item-accept'),
            $cookieRefuse = $cookieBanner.find('.item-refuse')
        ;


        /*
		|
		| Cookie Manager
		|-----------------
		*/
        new CookieManager($cookieBanner, {
            name: 'adveris_cookie_use',
            duration: 30,
            buttons: {
                accept: $cookieAccept,
                refuse: $cookieRefuse,
                close: $cookieClose
            },
            onAccept: () => {
                console.log('accpeted')
            },
            onRefuse: () => {
                console.log('refused')
            }
        });

        /*
		|
		| Scroll link
		|-----------------
		*/
        jQuery('a[href*=\\#]').click(function (event) {
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top
            }, 1000);
            event.preventDefault();
        });

        /*
		|
		| Video play/stop
		|-----------------
		*/
        if ($('.btn-video').length) {
            const video = document.querySelector(".bg-video");
            const btn = document.querySelector(".btn-video");
            const icplay = document.querySelector(".play");
            const icpause = document.querySelector(".pause");

            btn.addEventListener("click", function () {
                if (video.paused) {
                    video.play();
                    $('.play').hide();
                    $('.pause').show();
                } else {
                    video.pause();
                    $('.play').show();
                    $('.pause').hide();
                }
            });
        }

        /*
		|
		| Anim chiffres
		|-----------------
		*/
        if ($('.nbr').length) {
            var a = 0;
            $(window).scroll(function () {
                var oTop = $('.chiffre').offset().top - window.innerHeight;

                if (a == 0 && $(window).scrollTop() > oTop) {
                    $('.nbr').each(function () {
                        var $this = $(this),
                            countTo = $this.attr('data-count');
                        $({
                            countNum: $this.text()
                        }).animate({
                                countNum: countTo
                            },
                            {
                                duration: 2000,
                                easing: 'swing',
                                step: function () {
                                    $this.text(Math.floor(this.countNum));
                                },
                                complete: function () {
                                    $this.text(this.countNum);
                                    //alert('finished');
                                }

                            });
                    });
                    a = 1;
                }
            });
        }

        /*
        |
        | Slider galerie
        |-----------------
        */
        var swiperqsn = new Swiper('.swiper-qsn', {
            slidesPerView: 1.3,
            loop: true,
            autoplay: {
                delay: 5000,
            },
            speed: 1400,
            loopedSlides: 50,
            centeredSlides: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
            breakpoints: {
                3: {
                    slidesPerView: 1
                },
                992: {
                    slidesPerView: 1,
                },
                1200: {
                    slidesPerView: 1.3,
                }
            }
        });

        /*
        |
        | Slider galerie
        |-----------------
        */
        var swiperhistoire = new Swiper('.swiper-histoire', {
            slidesPerView: 4,
            spaceBetween: 30,
            centeredSlides: true
        });

        /*
        |
        | Slider partenaires
        |-----------------
        */
        var swiperpartenaires = new Swiper('.swiper-partenaires', {
            slidesPerView: 5.8,
            loop: true,
            spaceBetween: 50,
            autoplay: {
                delay: 2000,
            },
            speed: 1200,
            breakpoints: {
                3: {
                    slidesPerView: 2
                },
                992: {
                    slidesPerView: 3
                },
                1200: {
                    slidesPerView: 5
                }
            }
        });


        /*
        |
        | Slider home
        |-----------------
        */
        var swiperscroll = new Swiper('.swiper-scroll', {
            slidesPerView: 1,
            spaceBetween: 80,
            breakpoints: {
                1200: {
                    slidesPerView: 1.8,
                    spaceBetween: 40
                },
            },
            scrollbar: {
                el: '.swiper-scrollbar',
            },
        });
        $('.swiper-scrollbar').show();


        /* Menu fix */
        $('.item-burger').click(function (event) {
            if ($("body").hasClass('o-h')) {
                $('body').removeClass('o-h');
            } else {
                $('body').addClass('o-h');
            }
        });

        /*
        |
        | Réduir le menu au scroll
        |-------------------------
        */
        function minimenu() {
            if ($(window).scrollTop() > 50) {
                $('#header').addClass('sticky-menu');
            } else {
                $('#header').removeClass('sticky-menu');
            }
        }

        minimenu();
        $(window).scroll(function () {
            minimenu();
        });


        /*
        |
        | Tabs histoire
        |-----------------
        */
        const buttonElement = document.querySelectorAll('.tablinks');
        const tabContent = document.querySelectorAll(".tabcontent");
        tabContent[0].style.display = "flex";
        buttonElement.forEach(function (i) {
            i.addEventListener('click', function (event) {
                for (let x = 0; x < buttonElement.length; x++) {
                    if (event.target.id == buttonElement[x].id) {
                        buttonElement[x].className = buttonElement[x].className.replace(" active", "");
                        tabContent[x].style.display = "flex";
                    } else {
                        tabContent[x].style.display = "none";
                        buttonElement[x].className = buttonElement[x].className.replace(" active", "");
                    }
                }
            });
        });
        jQuery('.tablinks').click(function (event) {
            jQuery(this).addClass('active');
        });

        $body.on('loaderEnd', () => console.log('ended'))
    }
}
